import styled from "styled-components";


const Section = styled.section`
  position: relative;
  padding: 60px 0;

  width: 100%;

  display: flex;
  align-items: center;
  
  @media (min-width: 992px) {
    // desktop
    //margin: 20px 0;
    padding: 0 60px 40px;
    min-height: 540px;

  }
`;


const SectionTitle = styled.h2`
  //font-size: 6.5rem;
  font-size: 20vw;
  font-weight: 300;
  line-height: 1.0;

  color: #fff;
  text-align: center;

  margin: 20px 0 30px 0;
  @media (min-width: 992px) {

    margin: 0 0 80px 0;
    font-size: 112px;
    line-height: normal;
  }
`;


export {
    Section,
    SectionTitle
};