import React from "react";

import {Section, SectionTitle} from "components/ReusableComponents/Section";
import Text from "components/ReusableComponents/Text";
import {Box, BoxTitle} from "components/ReusableComponents/Box";

import './style.scss';


import ContactForm from "./ContactForm";


class Contacts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    render() {
        return (
            <Section id="contacts" style={{minHeight: this.state.width < 992 ? 'auto' : this.state.height + 'px'}}>
                <div className="container">
                    <SectionTitle>Контакты</SectionTitle>

                    <div className='flex-row-pc-column-mobile'>
                        <div className='contacts-mails-container'>
                            <div>
                                <a href='mailto:business@impord.ru'>
                                    <div>
                                        <Box className="contacts-custom-box">
                                            <Text className="contacts-custom-text">
                                                Для новых заявок
                                            </Text>
                                            <BoxTitle
                                                className="contacts-custom-box-title">business@impord.ru</BoxTitle>
                                        </Box>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <a href='mailto:support@impord.ru'>
                                    <div>
                                        <Box className="contacts-custom-box">
                                            <Text className="contacts-custom-text">
                                                Для существующих заявок
                                            </Text>
                                            <BoxTitle className="contacts-custom-box-title">support@impord.ru</BoxTitle>
                                        </Box>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <ContactForm/>
                        </div>
                    </div>


                </div>
            </Section>
        );
    }
}

export default Contacts;
