import React, {Component} from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";


import {Box, BoxTitle} from "components/ReusableComponents/Box";


import './style.scss';
import './tick_animation.scss';


class ContactForm extends Component {
    state = {
        name: "",
        message: "",
        email: "",
        subject: "",
        sent: false,
        buttonText: "Отправить",
        emailError: false,
    };
    resetForm = () => {
        this.setState({
            name: "",
            message: "",
            email: "",
            subject: "",
            buttonText: "Отправлено",
        });

        setTimeout(() => {
            this.setState({sent: false});
        }, 3000);
    };

    handleChangeEmail(e) {
        if (
            !e.target.value.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            this.setState({
                email: e.target.value,
            });
            this.setState({emailError: true});

            if (this.state.email === "") {
                // check if the input is empty
                this.setState({emailError: false});
            }
        } else {
            this.setState({email: e.target.value, emailError: false});
        }
    }

    formSubmit = async (e) => {
        e.preventDefault();
        // this.setState({
        //     buttonText: "отправка...",
        // });

        let data = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            subject: this.state.subject,
        };

        try {
            let response = await axios.post("http://178.140.196.192:9900/register_new_record/", data);
            console.log("response:");
            console.log(response);
            // this.setState({sent: true}, this.resetForm());
            this.setState({sent: true});
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        return (

            <div>


                <Box className='container'>

                    {this.state.sent
                        ?
                        <div className="contact-form">
                            <BoxTitle className='contact-form-custom-box-title'>Заявка
                                принята</BoxTitle>
                            <div className="tick-animation-container">

                                <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                    <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                                        <circle className="success-circle-outline" cx="12" cy="12" r="11.5"/>
                                        <circle className="success-circle-fill" cx="12" cy="12" r="11.5"/>
                                        <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"/>
                                    </g>
                                </svg>

                            </div>
                        </div>
                        :

                        <div>
                            <form className="contact-form" onSubmit={(e) => this.formSubmit(e)}>
                                <BoxTitle className='contact-form-custom-box-title'>Напишем сами</BoxTitle>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Введите имя"
                                    label="Имя"
                                    variant="outlined"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    required
                                    type="text"/>


                                <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    placeholder="Введите email"
                                    variant="outlined"
                                    value={this.state.email}
                                    onChange={(e) => this.handleChangeEmail(e)}
                                    error={this.state.emailError}
                                    required
                                    type="email"/>

                                <div className='flex-row-center'>
                                    <button type="submit" className='button-style-contacts'>
                                        {this.state.buttonText}
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                </Box>
            </div>

        );
    }
}

export default ContactForm;