import styled from "styled-components";

const Box = styled.div`
  height: 100%;
  
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  text-align: center;
  margin: 50px 0;
  
  box-shadow: 1px 1px 8px 1px rgba(255, 255, 255, 0.3); 
  
  cursor: default;
  
  transition: all 0.5s;
  @media (min-width: 992px) {
    margin: 10px 16px;
    
  }
`;


const BoxTitle = styled.h4`
  font-size: 42px;
  font-weight: 500;
  line-height: normal;
  color: #2e2e2e;
  cursor: default;
  
  @media (min-width: 992px) {
    font-weight: 500;
    line-height: 1.0;
    font-size: 26px;
  }
`;

export {
    Box,
    BoxTitle
};