import styled from "styled-components";

const Text = styled.p`
  padding: 10px 20px;
  
  height: 100%;
  
  display: flex;
  align-items: center;
  
  font-size: 20px;
  font-weight: normal;
  line-height: 1.2;

  color: #565656;
  margin-bottom: 0;
  max-width: 350px;
  cursor: default;

  font-family: 'BlenderPro-Book', sans-serif;

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 1.24;
    margin-bottom: -2px;
  }
`;

export default Text;