import React, {Fragment} from "react";
import AOS from "aos";


import NewHeader from "./components/NewHeader";
import Home from "./components/Home";
import About from "./components/About";
import Contacts from "./components/Contacts";


import Approach from "./components/Approach";


import Footer from "./components/Footer";


import "aos/dist/aos.css";
import "./assets/styles/main.scss";
import "./assets/styles/colors.scss";


const App = () => {
    AOS.init({once: true});


    return (

        <Fragment>
            <NewHeader/>
            <main style={{overflowX: 'hidden', overflowY: 'hidden'}}>

                {/*<Img id='page-background' src={bgImg}/>*/}
                <Home/>
                <div className='delim'/>
                <About/>
                <div className='delim'/>
                <Approach/>
                <div className='delim'/>

                <Contacts/>

                <Footer/>

            </main>
        </Fragment>
    );
};

export default App;
