import React from "react";
import {Link, animateScroll as scroll} from "react-scroll";
import PulseText from 'react-pulse-text';


import styled from "styled-components";
import './style.scss';

import CargoAnimation from './cargoAnimation'
import PlanetAnimation from './planetAnimation'


const Section = styled.section`
  position: relative;
  height: 90vh;
  width: 100%;
  padding: 90px 10px 110px 10px;

  @media (min-width: 992px) {
    height: auto;
    width: 100%;
    padding: 100px 80px 130px 50px;

  }


  .container {
    z-index: 1;
    user-select: none;
    cursor: default;
  }
`;


const BetaAlert = styled.p`
  position: fixed;
  bottom: 0px;
  right: 8px;
  color: white;
  font-size: 20px;

  text-align: left;
  line-height: 1.0;
  margin-left: 10px;
  font-weight: 400;
  z-index: 100000;
`;


const HomeTitle = styled.h1`

  position: relative;
  //font-size: 7rem;
  font-size: min(22vw, 130px);


  text-align: left;

  line-height: 1.0;
  color: white;

  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 150px;
  }


`;


const HomeDescription = styled.h2`

  position: relative;
  color: rgba(255, 255, 255, 0.8);
  //font-size: 3rem;
  font-size: min(8.6vw, 50px);
  font-weight: 400;
  line-height: 1.0;

  margin: 5px 0;

  @media (min-width: 992px) {
    padding-right: 0;
     font-size: 50px;
    line-height: 0.9
  }
`;


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    scrollTo = id => e => {

        e.preventDefault();
        scroll.scrollTo({
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint",
            containerId: id
        });


    };


    render() {
        return (
            <Section id="home"
                     style={{height: this.state.width < 992 ? 'none' : this.state.height + 'px'}}
                     className='home-container'>



                {/*<BetaAlert>*/}
                {/*    beta 29may*/}
                {/*</BetaAlert>*/}

                <div className='home-cargo-animation-container'>
                    <CargoAnimation/>
                </div>
                <div className='home-planet-animation-container'>
                    <PlanetAnimation/>
                </div>


                <div style={{width: '100%', height: '100%'}}>
                    <div className='title-container-home'>

                        <HomeTitle className=''>
                            IMPOR<span style={{color: '#b7b7b7'}}>D</span>
                        </HomeTitle>

                        <HomeDescription style={{zIndex: 11}}>
                            служба <br/>
                            параллельного <br/>
                            импорта


                        </HomeDescription>


                    </div>

                    <div className='flex-row-center'>
                        <Link
                            href="#"
                            className=""
                            activeClass=""
                            to="contacts"
                            spy={false}
                            smooth={true}
                            offset={0}
                            duration={400}
                        >
                            <button onSubmit={this.scrollTo('#contact')} className='button-style-home'>
                                Написать нам
                            </button>
                        </Link>


                    </div>
                </div>

            </Section>
        );
    }
}

export default Home;
