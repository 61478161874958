import React from "react";

import {Box} from "components/ReusableComponents/Box";
import './style.scss';

class CargoAnimation extends React.Component {
    render() {
        return (

            <Box className='cargo-custom-box'>
                <div className="cargo-animation-container">
                    <div className="car-wrapper">
                        <div className="car-wrapper_inner">

                            <div className="car_outter">
                                <div className="car">
                                    <div className="body">
                                        <div></div>
                                    </div>
                                    <div className="decos">
                                        <div className="line-bot"></div>
                                        {/*<div className="door">*/}
                                        {/*    <div className="handle"></div>*/}
                                        {/*    <div className="bottom"></div>*/}
                                        {/*</div>*/}
                                        <div className="window"></div>
                                        <div className="light"></div>
                                        <div className="light-front"></div>
                                        {/*<div className="antenna"></div>*/}
                                        <div className="cargo-logo-container">
                                            <span className="cargo-custom-text">IMPORD</span>
                                            {/*<div className="cone"></div>*/}
                                        </div>
                                        <span className="cargo-custom-subtext">организация<br/>импортных<br/>поставок</span>
                                    </div>
                                    <div>
                                        <div className="wheel"></div>
                                        <div className="wheel"></div>
                                    </div>
                                    <div className="wind">
                                        <div className="p p1"></div>
                                        <div className="p p2"></div>
                                        <div className="p p3"></div>
                                        <div className="p p4"></div>
                                        <div className="p p5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/*<div className="background-stuff">*/}
                    {/*    <div className="bg"></div>*/}
                    {/*    <div className="bg bg-2"></div>*/}
                    {/*    <div className="bg bg-3"></div>*/}
                    {/*    <div className="ground"></div>*/}
                    {/*</div>*/}
                </div>
            </Box>

        );
    }
}

export default CargoAnimation;