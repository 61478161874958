import React from "react";
import {Section, SectionTitle} from "components/ReusableComponents/Section";
import {Box, BoxTitle} from "components/ReusableComponents/Box";
import Text  from "components/ReusableComponents/Text";

import styled from "styled-components";
import './style.scss';



const IconWrap = styled.div`
  width: 120px;
  height: 120px;
  
  border-radius: 14px;
 
  margin-bottom: 18px;
  position: relative;
  border: solid 2px white;
  
  
  
  @media (min-width: 992px) {
    margin-bottom: 30px;
  }
`;

class Approach extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }


    render() {
        return (
            <Section id="approach" style={{minHeight: this.state.width < 992 ? 'auto' : this.state.height + 'px'}}>
                <div className="container">
                    <SectionTitle>Подход</SectionTitle>
                    {/*<SubTitle>in a nutshell</SubTitle>*/}
                    <div className="row-container-approach">
                        <div>
                            <Box className='approach-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <h1 className='center-approach'>
                                        1
                                    </h1>
                                </IconWrap>
                                <BoxTitle className='approach-custom-box-title'>Запрос</BoxTitle>
                                <Text className='approach-custom-text'>
                                    анализируем запрос и проводим оценку параметров поставки
                                </Text>
                            </Box>
                        </div>
                        <div>
                        <Box className='approach-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <h1 className='center-approach'>
                                        2
                                    </h1>
                                </IconWrap>
                                <BoxTitle className='approach-custom-box-title'>Предложение</BoxTitle>
                                <Text className='approach-custom-text'>

                                    подготавливаем и согласовываем проект договора на поставку


                                </Text>
                            </Box>
                        </div>
                        <div>
                            <Box className='approach-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <h1 className='center-approach'>
                                        3
                                    </h1>
                                </IconWrap>
                                <BoxTitle className='approach-custom-box-title'>
                                    Доставка
                                </BoxTitle>
                                <Text className='approach-custom-text'>
                                    организуем доставку до склада согласно договору
                                </Text>
                            </Box>
                        </div>
                    </div>
                </div>
            </Section>
        );
    }


}

export default Approach;
