import React from "react";
import {Section, SectionTitle} from "components/ReusableComponents/Section";
import {Box, BoxTitle}  from "components/ReusableComponents/Box";
import Text  from "components/ReusableComponents/Text";

import styled from "styled-components";

import TimeIcon from "../Icons/placeholder.png";
import CargoIcon from "../Icons/placeholder.png";


import './style.scss';



const Img = styled.img`
  // position: relative;
  // width: ${window.innerWidth / 1.2}px; /* width of container */
  width: 100%; /* width of container */
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
  
  
`;

const IconWrap = styled.div`
  width: 180px;
  height: 180px;

  //margin: 50px 0;
  position: relative;
  > svg {
    transition: all 0.3s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    > svg {
      transform: translate(-50%, -50%) rotateY(360deg);
    }
  }
  @media (min-width: 992px) {
    width: 180px;
    height: 180px;
    //margin-bottom: 30px;
  }
`;


class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    render() {
        return (
            <Section id="about" style={{minHeight: this.state.width < 992 ? 'auto' : this.state.height + 'px'}}>
                <div className='container'>

                    <SectionTitle>О нас</SectionTitle>
                    <div className='row-container-about'>
                        <div>
                            <Box className='about-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <Img src={TimeIcon} stroke='green'/>
                                </IconWrap>
                                <BoxTitle>Выгода</BoxTitle>
                                <div>
                                    <Text>
                                        Итоговая стоимость единицы товара в среднем составляет 118% от заводской цены
                                    </Text>
                                </div>
                            </Box>
                        </div>
                        <div>
                            <Box className='about-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <Img src={CargoIcon}/>
                                </IconWrap>
                                <BoxTitle>
                                    Охват
                                </BoxTitle>
                                <Text>
                                    Работаем с поставщиками со всего мира, несмотря на санкции
                                </Text>
                            </Box>
                        </div>
                        <div>
                            <Box className='about-custom-box' data-aos="fade-up">
                                <IconWrap>
                                    <Img src={CargoIcon}/>
                                </IconWrap>
                                <BoxTitle>
                                    Оперативность
                                </BoxTitle>
                                <Text>
                                    Среднее время полного цикла поставки — 2 месяца
                                </Text>
                            </Box>
                        </div>
                        {/*<div>*/}
                        {/*    <Box className='about-custom-box' data-aos="fade-up">*/}
                        {/*        <IconWrap>*/}
                        {/*            <Img src={CargoIcon}/>*/}
                        {/*        </IconWrap>*/}
                        {/*        <BoxTitle>*/}
                        {/*            Объем*/}
                        {/*        </BoxTitle>*/}
                        {/*        <Text>*/}
                        {/*            Минимальный объем поставки — 96м³ (одна фура)*/}

                        {/*        </Text>*/}
                        {/*    </Box>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Section>
        );
    }
};

export default About;
