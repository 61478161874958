import React from "react";

import { Link, animateScroll as scroll } from "react-scroll";

// import Logo from "./logo.jpg";

import './style.scss';



class NewHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            prevPos: 0,
            scrollPos: 0,
            show: true,
            burgerActive: false
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.hideUpScrollMenu = this.hideUpScrollMenu.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    handleScroll() {
        this.setState({
                scrollPos: ((document.body.getBoundingClientRect().top - this.state.prevPos) < 0) ? document.body.getBoundingClientRect().top + 200: this.state.scrollPos,

                prevPos: document.body.getBoundingClientRect().top,
                show: (document.body.getBoundingClientRect().top > this.state.scrollPos || document.body.getBoundingClientRect().top > -300)
            },
        )

    }

    toggleMenu = data => e => {


        if (this.state.width < 992) {

            document.getElementById('header_burger').classList.toggle("active-header");
            document.getElementById('header_menu').classList.toggle("active-header");
            // document.getElementById('home').classList.toggle("hide-home");
            // document.getElementById('header_menu').classList.add("navbarHidden-header");
            document.body.classList.toggle('lock-header');
        }
        if (data !== 'burgerOpener') {
            setTimeout(this.hideUpScrollMenu, 800);
        }
    }

    hideUpScrollMenu() {
        let homeHeight = isNaN(parseInt(document.getElementById('home').style.height)) ?
            -300 :
            60 - parseInt(document.getElementById('home').style.height)
        ;

        let boundingClient = isNaN(parseInt(document.body.getBoundingClientRect().top)) ? 0 :
            document.body.getBoundingClientRect().top

        if (boundingClient < homeHeight) {
            this.setState({
                    scrollPos: document.body.getBoundingClientRect().top,
                    show: false
                },
            )
        }
    }
    scrollTo = id => e => {

        e.preventDefault();
        scroll.scrollTo({
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint",
            containerId: id
        });


    };
    render() {




        return (
            <div id='navbarNew' className={this.state.show ?
                'navbar-header navbarActive-header' :
                'navbar-header navbarHidden-header'}>
                <div className='container-header'>
                    <div className="header_body-header">
                        <Link
                            href="#home"
                            className="header_logo-header"
                            activeClass=""
                            to="home"
                            spy={false}
                            smooth={true}
                            offset={0}
                            duration={400}


                        >
                            {/*<div className="header_link-header">*/}
                            {/*    impord*/}
                            {/*</div>*/}
                        </Link>

                        <div id='header_burger' className='header_burger-header'
                             onClick={this.toggleMenu('burgerOpener')}>
                            <span />
                            {/*<span />*/}
                        </div>
                        <div id='header_menu' className='header_menu-header'>
                            <ul className="header_list-header">
                                <li>
                                    <Link
                                        href="#"
                                        className="header_link-header"
                                        activeClass=""
                                        to="about"
                                        spy={false}
                                        smooth={true}
                                        offset={0}
                                        duration={400}
                                        onClick={this.toggleMenu('Link')}

                                    >
                                        <div className="header_link-header">
                                            о нас
                                        </div>
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link*/}
                                {/*        href="#"*/}
                                {/*        className="nav-link"*/}
                                {/*        activeClass="active"*/}
                                {/*        to="about"*/}
                                {/*        spy={true}*/}
                                {/*        smooth={true}*/}
                                {/*        offset={0}*/}
                                {/*        duration={400}*/}
                                {/*        onClick={this.toggleMenu('Link')}*/}
                                {/*    >*/}
                                {/*        <div className="header_link-header">*/}
                                {/*            о нас*/}
                                {/*        </div>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}

                                <li>
                                    <Link
                                        href="#"
                                        className="nav-link"
                                        activeClass="active"
                                        to="approach"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={400}
                                        onClick={this.toggleMenu('Link')}
                                    >
                                        <div className="header_link-header">
                                            подход
                                        </div>
                                    </Link>

                                </li>

                                <li>
                                    <Link
                                        href="#"
                                        className="nav-link"
                                        activeClass="active"
                                        to="contacts"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={400}
                                        onClick={this.toggleMenu('Link')}
                                    >
                                        <div className="header_link-header">
                                            контакты
                                        </div>
                                    </Link>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default NewHeader;



