import React from "react";
import './style.scss'



class PlanetAnimation extends React.Component {

    render() {
        return (


            <div className="earth-container">
                <div
                    className="globe">
                </div>
            </div>

        );
    }
}

export default PlanetAnimation;
